/**
 * @program: oa2.0
 * @author: ly
 * @description: FinanceComp
 * @create: 2023-08-25 09:33
 **/

let FinanceComp = [
    {
        path: '',
        component: () => import("@/page/Other/OAMenuDefaultPage/MenuList"),
        name: "FinanceComp",
        cnName: "默认页面",
        children: () => {
            return FinanceComp
        }
    },
    /** 财务 **/
    {
        path: 'CardManager',
        name: "CardManager",
        cnName: "资金账户设定",
        component: () => import("@/page/Finance/FinancialManager/BankManager/CardManager"),
    },
    {
        path: 'yearBalance',
        component: () => import("@/page/Finance/FinancialManager/yearBalanceMange/yearBalance"),
        name: "yearBalance",
        cnName: "年初余额"
    },
    {
        path: 'financeSuject',
        component: () => import("@/page/Finance/FinancialManager/financeSujectMange/financeSuject"),
        name: "financeSuject",
        cnName: "财务科目管理"
    },
    {
        path: 'BankFlowRecords',
        component: () => import("@/page/Finance/FinancialManager/BankFlowRecords/BankFlowRecords"),
        name: "BankFlowRecords",
        cnName: "账户收付款管理"
    },
    /* {path: 'financeReport', component: financeReport, name:"financeReport",cnName:"财务报表"},*/

    /** 账单 **/
    {
        path: 'BillEntry',
        component: () => import("@/page/Finance/BillManager/BillEntry"),
        name: "BillEntry",
        cnName: "入账管理"
    },
    {
        path: 'BillManager',
        component: () => import("@/page/Finance/BillManager/BillManager"),
        name: "BillManager",
        cnName: "账单管理"
    },
    {
        path: 'BillStatistics',
        name: "BillStatistics",
        cnName: "账单统计",
        component: () => import("@/page/Finance/BillManager/BillStatistics")
    },
    {
        path: 'OtherPayApply',
        component: () => import("@/page/Finance/FinancialManager/OtherPayApply/OtherPayApply"),
        name: "OtherPayApply",
        cnName: "资金账户支付申请"
    },
    {
        path: 'BillSetting',
        component: () => import("@/page/Finance/BillManager/BIllSetting/BillSetting"),
        name: "BillSetting",
        cnName: "报账相关设定"
    },
    {
        path: 'DepartmentBillEntry',
        component: () => import("@/page/Finance/BillManager/DepartmentBillEntry"),
        name: "DepartmentBillEntry",
        cnName: "部门入账管理"
    },
    {
        path: 'DepartmentBillManager',
        component: () => import("@/page/Finance/BillManager/DepartmentBillManager"),
        name: "DepartmentBillManager",
        cnName: "部门账单管理"
    },
    {
        path: 'DepartmentBillStatistics',
        name: "DepartmentBillStatistics",
        cnName: "部门账单统计",
        component: () => import("@/page/Finance/BillManager/DepartmentBillStatistics"),
    },
]

export default FinanceComp
