/**
 * @program: 前台
 * @author: ly
 * @description: routerGuards
 * @create: 2022-12-20 11:04
 **/
import {cryptoGetItem} from "@/assets/webCryptoApply";
import {message } from 'ant-design-vue';


function RouterGuards(router){
    router.beforeEach(async (to, from,next) => {
        if (checkRoute(to.path,router)){
            if(await checkAuth(to)){
                next()
            }
            else {
                message.error("没有权限访问");
                next({name: from.name })
            }
        }
        else{
            next({path: '/404' })
        }
    })
}

async function getMenu(){
    let json = await cryptoGetItem("__menuInfo");
    if(json){
        return JSON.parse(json)
    }
    else{
        return []
    }

}

async function checkAuth(to) {
    let authName = to.name;
    if(to.meta.checkAuth === "parentAuth"){//检查父路由的权限
        let matchedRoutes = to.matched;
        let pRoute = matchedRoutes[matchedRoutes.length - 2];
        authName = pRoute.name;
    }
    if(to.meta.checkAuth === "notNeed"){//不需要权限
        return true
    }
    let menus = await getMenu();
    let ep = [];
    getAllNodes (ep, menus);
    for(let menu of ep){
        if(authName === menu.component){
            return true
        }
    }
    return false
}



function checkRoute(path,router){
    let routes = router.getRoutes();
    for(let i in routes){
        if(routes[i].path === path){
            return true
        }
    }
    return false
}

function getAllNodes (nodes, treeData) {
    for (let item of treeData) {
        nodes.push(item);
        if (item.children) {
            nodes = getAllNodes(nodes, item.children)
        }
    }
    return nodes
}
export default RouterGuards

